// ProductDetail.js

import React, { useEffect, useState } from 'react';

import Nav from '../../components/nav';
import Footer from '../../components/footer';

import ProductBreadcrumb from '../productPageBreadcrumb';
import ProductDetailSlide from '../productDetailSlide';
import ProductDetailText from '../productDetailText';
import withCart from '../../HOC/withCart';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon,ShoppingBagIcon} from '@heroicons/react/24/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ProductDetail = ({

  cart, addToCart, removeFromCart, updateCartItem, getTotalItems
}) => { 

  let [relatedProducts, setRelatedProducts] = useState([])

  // ############ MENU ############
  const [menuItems, setMenuItems] = useState([]);
  const [logoURL, setLogoURL] = useState('/images/logo.svg');  // Default logo
  useEffect(() => {
      // Fetch the menu items from WordPress REST API
      fetch('/wp-json/menus/v1/menus/MainMenu')
        .then((response) => response.json())
        .then((data) => {
          setMenuItems(data.items);
        });

      // Fetch the theme logo
      fetch('/wp-json/theme/v1/logo')  
          .then((response) => response.json())
          .then((data) => {
              // console.dir(data)
              setLogoURL(data.logo);
          });



  }, []);


  const [post, setPost] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);


  useEffect(() => {
    const postId = document.getElementById("post-detail-root").getAttribute("data-post-id");
    fetch(`/wp-json/wp/v2/posts/${postId}`)
      .then(response => response.json())
      .then(data => {
        console.dir(data);
        setPost(data);

        // Fetch the featured image
        const mediaId = data.featured_media;
        return fetch(`/wp-json/wp/v2/media/${mediaId}`);
      })
      .then(response => response.json())
      .then(data => {
        setFeaturedImage(data.source_url);
      })
      .catch(error => console.log(error));



      fetch(`/wp-json/mytheme/v1/related-posts/${postId}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(relatedPosts => {
            console.log('Related Posts:', relatedPosts);
            console.dir(relatedPosts)
            setRelatedProducts(relatedPosts)
            // Process and display the related posts
        })
        .catch(error => {
            console.error('Error fetching related posts:', error);
        });
  }, []);


  const handleTest = () => {
    addToCart({
        id:post.id,
        name:post.title.rendered,
        detail:post.excerpt.rendered,
        slug:post.slug,
        image:featuredImage,
    })
}
  
  

  if (!post) return <div>Loading...</div>;

  return (
    <div>
    <Disclosure as="nav" className="bg-gray-800">
    {({ open }) => (
      <>
      <div className="bg-dark w-full">
        <div className="
            w-full
            h-14

            md:w-full
            
            lg:w-[1000px]
            lg:mx-auto

            xl:w-[1200px]

            flex
            justify-between
            ">
                <div className="ml-2 md:ml-3 lg:ml-0 mt-2">
                    <a href="/">
                        <img src={logoURL} alt="logo" className="h-8 md:h-10" />
                    </a>
                </div>
                <div className="hidden mt-4 mr-10 sm:block">
                    <ul className="flex space-x-12">
                        {menuItems?.map((item) => (
                        <li key={item.ID}>
                            <a href={item.url} className="text-white text-sm hover:text-main transition">{item.title}</a>
                        </li>
                        ))}
                        <li className="flex hover:cursor-pointer hover:opacity-30 transition">
                            <a href="/cart" className="flex">
                              <ShoppingBagIcon className="w-6 h-6 text-white" />
                              <div className="text-black ml-1 mt-0.5 pt-[3px] text-xs bg-white px-2 rounded-full">{getTotalItems()}</div>
                            </a>
                        </li>
                    
                    </ul>
                </div>

                <div className="inset-y-0 mr-4 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                    </Disclosure.Button>
                </div>
        </div>
        <div className="
            w-full
            bg-[#353C42]

            md:w-full
            md:bg-[#353C42]
            
            lg:w-[1000px]
            lg:mx-auto
            lg:bg-[#353C42]

            xl:w-[1200px]
            xl:bg-[#353C42]

            h-[5px]
            flex
            ">&nbsp;</div>
    </div>



    <Disclosure.Panel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
        {menuItems?.map((item) => (
            <Disclosure.Button
            key={`mobile-${item.ID}`}
            as="a"
            href={item.url}
            className={classNames(
                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium'
            )}
            // aria-current={item.current ? 'page' : undefined}
            >
            {item.title}
            </Disclosure.Button>
        ))}
        </div>
    </Disclosure.Panel>

    </>
    )}
    </Disclosure>
    
    <ProductBreadcrumb categoryId={post.categories[0]} />
    <div className="
    w-full md:w-[800px] mx-auto
    pb-2
    px-8
        ">
          <h1 className="text-4xl font-bold">{post.title.rendered}</h1>
      </div>
    <ProductDetailSlide data={featuredImage} />
    <ProductDetailText data={post} clickAdd={handleTest} total={getTotalItems()} related={relatedProducts} />
    





    <Footer />
    </div>
  );
}

export default withCart(ProductDetail)