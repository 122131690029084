// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CategoryPage from './components/pages/category'; // import the new component
import ProductDetail from './components/pages/productDetail';
import Contact from './components/pages/contact';
import CustomStatic from './components/pages/customStatic';
import reportWebVitals from './reportWebVitals';
import PCart from './components/pages/cart';

const root = document.getElementById('root');
const categoryRoot = document.getElementById('category-root');
const productDetailRoot = document.getElementById('product-detail-root');
const contactRoot = document.getElementById('contact-root');
// const customStaticRoot = document.getElementById('custom-static-root');
const customPageRoot = document.getElementById('custom-page-root');
const cartRoot = document.getElementById('cart-root');

const postDetailRoot = document.getElementById('post-detail-root');

if (root) {
  const rootReact = ReactDOM.createRoot(root);
  rootReact.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

if (categoryRoot) {
  const categoryRootReact = ReactDOM.createRoot(categoryRoot);
  categoryRootReact.render(
    <React.StrictMode>
      <CategoryPage />
    </React.StrictMode>
  );
}

if (productDetailRoot) {
  ReactDOM.render(<ProductDetail />, productDetailRoot);
}


if (contactRoot) {
  ReactDOM.render(<Contact />, contactRoot);
}

if (cartRoot) {
  ReactDOM.render(<PCart />, cartRoot);
}

if (customPageRoot) {
  ReactDOM.render(<CustomStatic />, customPageRoot);
}

if (postDetailRoot) {
  ReactDOM.render(<ProductDetail />, postDetailRoot);
}


reportWebVitals();



// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
