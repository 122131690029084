import React, { useState, useEffect } from 'react';

const withCart = (WrappedComponent) => {
    return (props) => {
        // Initialize the cart state
        const [cart, setCart] = useState([]);

        // Load cart from localStorage on component mount
        useEffect(() => {
            console.log('call useEffect first time')
            const storedCart = localStorage.getItem('cart');
            console.dir(storedCart)
            if (storedCart&&storedCart.length > 0) {
                console.log('############# Initial cart from localStorage #############')
                console.dir(storedCart)
                try {
                    const parsedCart = JSON.parse(storedCart);
                    setCart(parsedCart);
                    console.dir(parsedCart)
                } catch (error) {
                    console.error("Error parsing cart data from localStorage:", error);
                    // Optionally clear or reset localStorage if corrupted
                }
            }
        }, []);

        const clearCart = () => {
            // Clear the cart in the component's state
            setCart([]);

            // Clear the cart data from localStorage
            localStorage.removeItem('cart');
        };

        // Cart manipulation functions
        const addToCart = (item) => {
            
            // Get the current cart from localStorage, or initialize as an empty array if null
            const storedCart = JSON.parse(localStorage.getItem('cart')) || [];

            // Add the new item to the cart array
            const newCart = [...storedCart, item];
        
            // Update the cart in the component's state
            setCart(newCart);
        
            // Save the updated cart to localStorage
            localStorage.setItem('cart', JSON.stringify(newCart));
        };
        


        const removeFromCart = (itemId) => {
            setCart(currentCart => {
                // Filter out the item to be removed
                const updatedCart = currentCart.filter(item => item.id !== itemId);
        
                // Update the localStorage with the new cart
                localStorage.setItem('cart', JSON.stringify(updatedCart));
        
                return updatedCart;
            });
        };
        

        const updateCartItem = (itemId, newItem) => {
            setCart(currentCart => currentCart.map(item => item.id === itemId ? newItem : item));
        };

        const getTotalItems = () => {
            console.dir(cart)
            return cart.length;
            // return cart.reduce((total, item) => total + item.quantity, 0);
        };

        return <WrappedComponent 
                    {...props} 
                    cart={cart} 
                    addToCart={addToCart} 
                    removeFromCart={removeFromCart} 
                    updateCartItem={updateCartItem} 
                    getTotalItems={getTotalItems}
                    clearCart={clearCart} // Passing the clearCart function as a prop
                    />;
    };
};

export default withCart;
