import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    
    // Responsive settings
    responsive: [
        {
          breakpoint: 640,
          
          settings: {
            autoplaySpeed:1000,
            autoPlay: true,
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
    ]
  };

export default function ProductList({
    data,
}){
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    // Update isSmallScreen based on window size
    useEffect(() => {
        const updateScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 640);
        };

        // Initial check
        updateScreenSize();

        // Listen for window resize events
        window.addEventListener('resize', updateScreenSize);

        // Cleanup
        return () => {
        window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (<div className="w-full my-2 ">
        <style jsx>{`
  @media (min-width: 1024px) {
    .slick-list {
      margin: 0 -10px;
    }
  }
  .slick-slide > div {
    margin: 0 10px;
  }
`}</style>
        <div className="
        w-full
        bg-green-500w
        px-0
        lg:px-0
        md:w-full
        lg:w-[1000px]
        lg:mx-auto
        xl:w-[1200px]
        flex
        items-center
        text-dark
            ">
        <div className="w-full mt-1 md:mt-2">
            <Slider {...settings}>
                {
                    data?.map((item,index)=>{
                        return (<div key={`pro-${index}`} className='h-[200px] md:h-[180px] w-full md:w-1/4'>
                            <a href={`/product/${item.slug}`}>
                        <div className="w-full h-full">
                            {/* <img src="/images/img-6.png" className="object-cover w-full h-full" /> */}
                            {item.featured_media_url && <img src={item.featured_media_url} className="object-cover w-full h-full" alt={item.title.rendered} />}
                        </div>
                        <div className="font-bold text-sm py-2">
                            {item.title.rendered}
                        </div>
                        </a>
                    </div>)
                    })
                }
                {/* <div className='h-[200px] md:h-[180px] w-full md:w-1/4'>
                    <div className="w-full h-full">
                        <img src="/wp-content/themes/weapons/images/img-6.png" className="object-cover w-full h-full" />
                    </div>
                    <div className="font-bold text-sm py-2">
                    Triple Modular Magazine Pouch
                    </div>
                </div>
                <div className='h-[200px] md:h-[180px] w-full md:w-1/4'>
                    <div className="w-full h-full">
                        <img src="/wp-content/themes/weapons/images/img-6.png" className="object-cover w-full h-full" />
                    </div>
                    <div className="font-bold text-sm py-2">
                    Triple Modular Magazine Pouch
                    </div>
                </div>
                <div className='h-[200px] md:h-[180px] w-full md:w-1/4'>
                    <div className="w-full h-full">
                        <img src="/wp-content/themes/weapons/images/img-6.png" className="object-cover w-full h-full" />
                    </div>
                    <div className="font-bold text-sm py-2">
                    Triple Modular Magazine Pouch
                    </div>
                </div>
                <div className='h-[200px] md:h-[180px] w-full md:w-1/4'>
                    <div className="w-full h-full">
                        <img src="/wp-content/themes/weapons/images/img-6.png" className="object-cover w-full h-full" />
                    </div>
                    <div className="font-bold text-sm py-2">
                    Triple Modular Magazine Pouch
                    </div>
                </div> */}
                
            </Slider>
            </div>

            </div>
    </div>)
}