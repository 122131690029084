import React, { useEffect, useState, Fragment } from 'react';

export default function Footer({

}){

    const [menuItems, setMenuItems] = useState([]);
    const [menu2Items, setMenu2Items] = useState([]);
    const [logoURL, setLogoURL] = useState('/images/logo.svg');  // Default logo

    useEffect(() => {
        // Fetch the menu items from WordPress REST API
        fetch('/wp-json/menus/v1/menus/MainMenu')
          .then((response) => response.json())
          .then((data) => {
            setMenuItems(data.items);
        });

        fetch('/wp-json/menus/v1/menus/footerMenu')
          .then((response) => response.json())
          .then((data) => {
            setMenu2Items(data.items);
        });

         // Fetch the theme logo
         fetch('/wp-json/theme/v1/logo')
         .then((response) => response.json())
         .then((data) => {
             setLogoURL(data.logo);
         });


    }, []);


    return (<div className="w-full  bg-dark">
        <div className="
        w-full
        px-0
        lg:px-0
        md:w-full
        lg:w-[1000px]
        lg:mx-auto
        xl:w-[1200px]
        flex
        items-center
        text-white
        ">

            <div className="py-6 mx-8 lg:mx-0 w-full flex">
                
                <div className="w-1/3">
                    <ul className="">
                        <li><a href="/" className="text-white text-sm hover:text-main transition">Home</a></li>
                        {menuItems?.map((item) => (
                        <li key={item.ID}>
                            <a href={item.url} className="text-white text-sm hover:text-main transition">{item.title}</a>
                        </li>
                        ))}
                    </ul>
                </div>

                <div className="w-1/3">
                    <ul className="">
                        {menu2Items?.map((item) => (
                        <li key={item.ID}>
                            <a href={item.url} className="text-white text-sm hover:text-main transition">{item.title}</a>
                        </li>
                        ))}
                    </ul>
                </div>

                <div className="hidden sm:block w-1/3 text-right">
                    <div className="flex justify-end">
                        <div className="mr-2">
                            <img src={logoURL} className='w-8 h-8' />
                        </div>
                        <div className="mt-1">WEAPON INDUSTIES CO., LTD</div>
                    </div>
                    <div className="text-xs mt-2 text-white/60">
                        888 Moo.3, Banmai Subdistrict, 
                        Pakkred district, Nonthaburi, 11120 
                        THAILAND
                    </div>
                </div>

            </div>

        </div>

        <div className="md:hidden text-white pl-6 pt-6 pb-6 bg-black">
            <div className="flex justify-start">
                <div className="mr-2">
                    <img src={logoURL} className='w-8 h-8' />
                </div>
                <div className="mt-1">WEAPON INDUSTIES CO., LTD</div>
            </div>
            <div className="text-xs mt-2 text-white/60 ml-0">
                888 Moo.3, Banmai Subdistrict, 
                Pakkred district, Nonthaburi, 11120 
                THAILAND
            </div>
        </div>
    </div>)
}