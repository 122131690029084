import React, { useEffect, useState } from 'react';

import Nav from '../../components/nav';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import BannerSubscribe from '../../components/bannerSubscribe';



import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon,ShoppingBagIcon} from '@heroicons/react/24/outline'
import withCart from '../../HOC/withCart';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const CustomPage = ({
  cart, addToCart, removeFromCart, updateCartItem, getTotalItems
}) => {

   // ############ MENU ############
   const [menuItems, setMenuItems] = useState([]);
   const [logoURL, setLogoURL] = useState('/images/logo.svg');  // Default logo
   useEffect(() => {
       // Fetch the menu items from WordPress REST API
       fetch('/wp-json/menus/v1/menus/MainMenu')
           .then((response) => response.json())
           .then((data) => {
           setMenuItems(data.items);
           });

       // Fetch the theme logo
       fetch('/wp-json/theme/v1/logo')  
           .then((response) => response.json())
           .then((data) => {
               // console.dir(data)
               setLogoURL(data.logo);
           });



   }, []); 


 const handleTest = () => {
   console.log('handleTest()')
   console.log('addToCart')
   addToCart({
       id:1,
       name:'test'
   })
}


  const [pageContent, setPageContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageFeaturedImage, setFeaturedImage] = useState('');

  useEffect(() => {
    const rootElement = document.getElementById("custom-page-root");
    const pageId = rootElement ? rootElement.getAttribute("data-page-id") : null;

    if (pageId) {
      fetch(`/wp-json/wp/v2/pages/${pageId}`)
        .then(response => response.json())
        .then(data => {
            console.dir(data)
            setFeaturedImage(data.featured_image.url);
            setPageContent(data.content.rendered);
            setPageTitle(data.title.rendered);
        });
    }
  }, []);

  return (
    <div>

<Disclosure as="nav" className="bg-gray-800">
    {({ open }) => (
      <>
      <div className="bg-dark w-full">
        <div className="
            w-full
            h-14

            md:w-full
            
            lg:w-[1000px]
            lg:mx-auto

            xl:w-[1200px]

            flex
            justify-between
            ">
                <div className="ml-2 md:ml-3 lg:ml-0 mt-2">
                    <a href="/">
                        <img src={logoURL} alt="logo" className="h-8 md:h-10" />
                    </a>
                </div>
                <div className="hidden mt-4 mr-10 sm:block">
                    <ul className="flex space-x-12">
                        {menuItems?.map((item) => (
                        <li key={item.ID}>
                            <a href={item.url} className="text-white text-sm hover:text-main transition">{item.title}</a>
                        </li>
                        ))}
                         <li className="flex hover:cursor-pointer hover:opacity-30 transition">
                            <a href="/cart" className="flex">
                              <ShoppingBagIcon className="w-6 h-6 text-white" />
                              <div className="text-black ml-1 mt-0.5 pt-[3px] text-xs bg-white px-2 rounded-full">{getTotalItems()}</div>
                            </a>
                        </li>
                    
                    </ul>
                </div>

                <div className="inset-y-0 mr-4 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                    </Disclosure.Button>
                </div>
        </div>
        <div className="
            w-full
            bg-[#353C42]

            md:w-full
            md:bg-[#353C42]
            
            lg:w-[1000px]
            lg:mx-auto
            lg:bg-[#353C42]

            xl:w-[1200px]
            xl:bg-[#353C42]

            h-[5px]
            flex
            ">&nbsp;</div>
    </div>



    <Disclosure.Panel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
        {menuItems?.map((item) => (
            <Disclosure.Button
            key={`mobile-${item.ID}`}
            as="a"
            href={item.url}
            className={classNames(
                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium'
            )}
            // aria-current={item.current ? 'page' : undefined}
            >
            {item.title}
            </Disclosure.Button>
        ))}
        </div>
    </Disclosure.Panel>

    </>
    )}
    </Disclosure>

      <div className="w-full relative h-[180px] md:h-[200px] overflow-hidden">
            <div className="
                h-[full]
                w-full
                md:w-full
                
                lg:w-[1000px]
                lg:mx-auto

                xl:w-[1200px]

                px-12 md:px-20 py-10
                absolute z-11 text-white
                flex flex-col items-start justify-end
                left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
                ">
                    <h1 className="text-2xl md:text-4xl font-bold leading-[40px] md:leading-[46px]">
                      {pageTitle}
                    </h1>
            </div>
            <div className="w-full h-full bg-black opacity-70 z-10 absolute">&nbsp;</div>
                <img src={pageFeaturedImage}
                 alt="hero" 
                 className="w-full h-full object-cover absolute z-0" /> {/* Updated classes here */}
        </div>

      <div
      className="
      h-[full]
      w-full
      md:w-full
      
      lg:w-[1000px]
      lg:mx-auto

      xl:w-[1200px]

      py-10
      
      weapons-content
      "
      >

        <div dangerouslySetInnerHTML={{ __html: pageContent }} />
      </div>


      <Footer />
    </div>
  );
}

export default withCart(CustomPage);
