import React, { useEffect, useState } from 'react';
import Nav from '../../components/nav';
import Footer from '../../components/footer';
import Collections from '../../components/collections';
import Banner from '../../components/banner';
import BannerSubscribe from '../../components/bannerSubscribe';

import Products from '../../components/products';

import PageHeader1 from '../pageHeader1';
import Headline1 from '../../components/utilities/headline1';


import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon,ShoppingBagIcon} from '@heroicons/react/24/outline'
import withCart from '../../HOC/withCart';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const CategoryPage = ({
  cart, addToCart, removeFromCart, updateCartItem, getTotalItems
}) => {

  // ############ MENU ############
  const [menuItems, setMenuItems] = useState([]);
  const [logoURL, setLogoURL] = useState('/images/logo.svg');  // Default logo
  useEffect(() => {
      // Fetch the menu items from WordPress REST API
      fetch('/wp-json/menus/v1/menus/MainMenu')
          .then((response) => response.json())
          .then((data) => {
          setMenuItems(data.items);
          });

      // Fetch the theme logo
      fetch('/wp-json/theme/v1/logo')  
          .then((response) => response.json())
          .then((data) => {
              // console.dir(data)
              setLogoURL(data.logo);
          });



  }, []); 


  const handleTest = () => {
    console.log('handleTest()')
    console.log('addToCart')
    addToCart({
        id:1,
        name:'test'
    })
  }


  const [categoryData, setCategoryData] = useState(null);
  const [products, setProducts] = useState([]);


  const categorySlug = window.wpReactTheme.categorySlug; // Get the slug from the window object

  const categoryCoverImage = window.wpReactTheme.categoryCoverImage;
  const categoryImage = window.wpReactTheme.categoryImage;
  const categoryBannerImage = window.wpReactTheme.categoryBannerImage;

  const subscriberImage = window.wpReactTheme.subscribeSettings.banner_image;
  const textLine1 = window.wpReactTheme.subscribeSettings.text_line_1;
  const textLine2 = window.wpReactTheme.subscribeSettings.text_line_2;

  useEffect(() => {
    fetch(`/wp-json/wp/v2/categories?slug=${categorySlug}`)
        .then((response) => response.json())
        .then((data) => {
            if (data && data.length > 0) {
                setCategoryData(data[0]);
            }
        })
        .catch((error) => {
            console.error('Error fetching category data:', error);
        });
}, [categorySlug]);

useEffect(() => {
  if (categoryData && categoryData.id) {
      fetch(`/wp-json/wp/v2/posts?categories=${categoryData.id}&_embed`) // <-- Add _embed parameter
          .then((response) => response.json())
          .then((data) => {
              const enhancedProducts = data.map(post => {
                  // Get the media URL
                  const mediaUrl = post._embedded['wp:featuredmedia'] 
                      && post._embedded['wp:featuredmedia'][0] 
                      && post._embedded['wp:featuredmedia'][0].source_url 
                      ? post._embedded['wp:featuredmedia'][0].source_url 
                      : null;
                  
                  return {
                      ...post,
                      featured_media_url: mediaUrl
                  };
              });

              setProducts(enhancedProducts); // <-- Set products with enhanced data
          })
          .catch((error) => {
              console.error('Error fetching products:', error);
          });
  }
}, [categoryData]);



  return (
    <div>

<Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
            <>
            <div className="bg-dark w-full">
                <div className="
                    w-full
                    h-14

                    md:w-full
                    
                    lg:w-[1000px]
                    lg:mx-auto

                    xl:w-[1200px]

                    flex
                    justify-between
                    ">
                        <div className="ml-2 md:ml-3 lg:ml-0 mt-2">
                            <a href="/">
                                <img src={logoURL} alt="logo" className="h-8 md:h-10" />
                            </a>
                        </div>
                        <div className="hidden mt-4 mr-10 sm:block">
                            <ul className="flex space-x-12">
                                {menuItems?.map((item) => (
                                <li key={item.ID}>
                                    <a href={item.url} className="text-white text-sm hover:text-main transition">{item.title}</a>
                                </li>
                                ))}
                                <li className="flex hover:cursor-pointer hover:opacity-30 transition">
                                    <a href="/cart" className="flex">
                                    <ShoppingBagIcon className="w-6 h-6 text-white" />
                                    <div className="text-black ml-1 mt-0.5 pt-[3px] text-xs bg-white px-2 rounded-full">{getTotalItems()}</div>
                                    </a>
                                </li>
                            
                            </ul>

                        </div>

                        <div className="inset-y-0 mr-4 left-0 flex items-center sm:hidden">
                            {/* Mobile menu button*/}
                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                            )}
                            </Disclosure.Button>
                        </div>
                </div>
                <div className="
                    w-full
                    bg-[#353C42]

                    md:w-full
                    md:bg-[#353C42]
                    
                    lg:w-[1000px]
                    lg:mx-auto
                    lg:bg-[#353C42]

                    xl:w-[1200px]
                    xl:bg-[#353C42]

                    h-[5px]
                    flex
                    ">&nbsp;</div>
            </div>



            <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2">
                {menuItems?.map((item) => (
                    <Disclosure.Button
                    key={`mobile-${item.ID}`}
                    as="a"
                    href={item.url}
                    className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    // aria-current={item.current ? 'page' : undefined}
                    >
                    {item.title}
                    </Disclosure.Button>
                ))}
                </div>
            </Disclosure.Panel>

            </>
            )}
            </Disclosure>


    <PageHeader1 data={categoryData} image={categoryCoverImage} />


    {/* <Headline1 firstText={'recommend'} secondText={'products'} /> */}
    {/* <Collections /> */}

    {
      categoryBannerImage && <Banner bannerLink={categoryData?.category_banner_link} src={categoryBannerImage} />
    }
    {/* <Banner 
      // firstText={`Unleash Your Tactical Potential`}
      secondText={`Unleash Your Tactical Potential`}
      image={`/images/img-12.png`}
      /> */}
      <Headline1 firstText={'our'} secondText={'products'} />
    {
        products.length > 0 ? <Products data={products} /> : <div className="w-full min-h-[350px] text-center text-2xl font-bold py-10">No products found</div> 
    }

      {/* <Products data={products} /> */}

      <BannerSubscribe line1={textLine1} line2={textLine2} images={subscriberImage} />

    <Footer />
    </div>
  );
};

export default withCart(CategoryPage);
