export default function Products({
    data,
}) {
    console.dir(data)
    return (
      <div className="w-full my-2 md:my-8">
        <div className="w-full px-2 lg:px-0 md:w-full lg:w-[1000px] lg:mx-auto xl:w-[1200px]">
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {
                data?.map((product)=>{
                    return (<div key={`p-${product.id}`} className='h-[200px] md:h-[180px] w-full mb-12'>
                            <a href={`/product/${product.slug}`}>
                            <div className="w-full h-full">
                            {product.featured_media_url && <img src={product.featured_media_url} className="object-cover w-full h-full" alt={product.title.rendered} />}
                                {/* <img src="/images/img-6.png" className="object-cover w-full h-full" /> */}
                            </div>
                            <div className="font-bold text-sm py-2">
                            {product.title.rendered}
                            </div>
                            </a>
                        </div>)
                })}
          </div>
        </div>
      </div>
    );
  }
  


//   let products = [
//     {
//         id:1,
//     },
//     {
//         id:2,
//     },
//     {
//         id:3,
//     },
//     {
//         id:4,
//     },
//     {
//         id:5,
//     },
//     {
//         id:6,
//     },
//     {
//         id:7,
//     },
//     {
//         id:8,
//     },
//     {
//         id:9,
//     },
//     {
//         id:10,
//     },
//   ]