import React, { useState, useEffect } from 'react';

export default function ProductBreadcrumb({
    categoryId
}){
    const [categoryName, setCategoryName] = useState('');

    // useEffect(() => {
    //     fetch(`/wp-json/mytheme/v1/category-name/${categoryId}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             console.dir(data)
    //             setCategoryName(data);
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // }, [categoryId]);


    return (<div className="
    w-full md:w-[800px] mx-auto
    px-8
        ">
    {/* ######## Breadcrumb ######### */}
    <div className="flex text-xs pt-4 px-4 md:px-0">
        <a href="/" className="text-dark/40 hover:text-dark transition">Product</a>
        &nbsp;|&nbsp;
        <span className="font-bold">Category</span>
    </div>
</div>)
}