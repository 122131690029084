import Slider from "react-slick";
const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // Responsive settings
    responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
  };

export default function Collections({
    data,
}){

    console.dir(data)

    return (<div className="w-full my-2 ">
        <style jsx>{`
            @media (min-width: 1024px) {
                .slick-list {
                margin: 0 -10px;
                }
            }
            .slick-slide > div {
                margin: 0 10px;
            }
            `}</style>

        <div className="
        w-full
        bg-green-500w
        px-0
        lg:px-0
        md:w-full
        lg:w-[1000px]
        lg:mx-auto
        xl:w-[1200px]
        flex
        items-center
        text-dark
            ">
            {/* <div className="w-full md:flex space-x-6 mx-4 bg-yellow-500"> */}
            <div className="w-full mt-1 md:mt-2">
            <Slider {...settings}>
                {
                    data?.map((item, index) => {
                        return (<div key={`c-${index}`} className='h-[200px] md:h-[180px] w-full md:w-1/3 relative'>
                        <a href={`/${item.slug}`}>
                        <div className="absolute z-11 text-white bg-dark bottom-0 left-0 px-4 py-1">
                            {item.name}
                        </div>
                        <div className="absolute z-10 w-full h-full">
                            <img src={item.category_image} className="object-cover w-full h-full" />
                        </div>
                        </a>
                    </div>)
                    })
                }
                {/* <div className='h-[200px] md:h-[180px] w-full md:w-1/3 relative'>
                    <div className="absolute z-11 text-white bg-dark bottom-0 left-0 px-4 py-1">
                        Equipment
                    </div>
                    <div className="absolute z-10 w-full h-full">
                        <img src="/images/img-2.png" className="object-cover w-full h-full" />
                    </div>
                </div>
                <div className='h-[200px] md:h-[180px] w-full md:w-1/3 relative'>
                    <div className="absolute z-11 text-white bg-dark bottom-0 left-0 px-4 py-1">
                        Gear
                    </div>
                    <div className="absolute z-10 w-full h-full">
                        <img src="/images/img-3.png" className="object-cover w-full h-full" />
                    </div>
                </div>
                <div className='h-[200px] md:h-[180px] w-full md:w-1/3 relative'>
                    <div className="absolute z-11 text-white bg-dark bottom-0 left-0 px-4 py-1">
                        Weapon
                    </div>
                    <div className="absolute z-10 w-full h-full">
                        <img src="/images/img-4.png" className="object-cover w-full h-full" />
                    </div>
                </div> */}
            </Slider>
                
            </div>
        </div>
    </div>)
}