
import { MagnifyingGlassIcon,ChevronDownIcon,EnvelopeIcon,LightBulbIcon } from '@heroicons/react/24/solid'


import Headline1 from '../../components/utilities/headline1';
import ProductList from '../../components/productList';
import withCart from '../../HOC/withCart';

const ProductDetailText = ({
    data,
    clickAdd,
    total,
    related
}) => {
    console.dir(data)

    return (<div className="
    w-full
    bg-green-500w
    px-0
    py-6
    lg:px-0
    md:w-full
    lg:w-[1000px]
    lg:mx-auto
    xl:w-[1200px]
    text-dark
        ">
    {/* ######## Breadcrumb ######### */}
    <div className="z-16 w-full md:w-[800px] mx-auto px-8 mb-16">
        <div className="flex flex-col-reverse md:flex-row">
            <div className="md:w-1/4 order-2 md:order-2 px-0 md:px-8">
                <button
                    onClick={clickAdd}
                // className="w-full bg-main hover:bg-main/80 transition text-white px-6 md:px-8 py-3 text-md flex"
                className="bg-main hover:bg-main/80 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                >
                    <EnvelopeIcon className="w-5 h-5 mr-2 mt-0.5" /> 
                    <div className="">Inquiry</div>
                </button>
            </div>
            <div className="md:w-3/4 px-0 order-1 md:order-1 mt-4 md:mt-0 weapons-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }}>
            {/* <div dangerouslySetInnerHTML={{ __html: pageContent }} /> */}
                {/* {
                    data.content.rendered
                } */}
            </div>
        </div>
    </div>






    <Headline1 firstText={'Related'} secondText={'products'} />
    <ProductList data={related} />

</div>)
}
export default ProductDetailText