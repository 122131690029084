export default function HeadlineStyle1({
    firstText,
    secondText
}) {
    return (
      <div className="w-full my-2">
        <style jsx>{`
        @media (max-width: 768px) {
          .remove-skew {
            transform: skewX(0deg) !important;
          }
        }
      `}</style>
        <div className="
            w-full

            px-2
            lg:px-0
            md:w-full
            lg:w-[1000px]
            lg:mx-auto
            xl:w-[1200px]
            flex
            items-center 
            text-dark
            ">
          
          {/* Skewed div with text */}
          <div style={{ transform: "skewX(-20deg)" }} className="remove-skew bg-main border-r-[8px] border-r-dark text-white px-4 py-2">
            <div style={{ transform: "skewX(20deg)" }}
            className="remove-skew uppercase text-xs sm:text-sm">
             <span className="font-bold">{firstText}</span>  {secondText?<span className="font-light">{secondText}</span> : null}
            </div>
          </div>
  
          {/* Line div */}
          <div className="flex-grow border-b-2 border-gray-400 mx-4"></div>  {/* make line take up remaining width and add some margin */}

        </div>
      </div>
    )
  }
  