import React, { Component } from "react";
import Slider from "react-slick";


export default function ProductDetailSlide({
  data,
}){
  console.log(data)
    const settings = {
        className: "slider variable-width",
        dots: false,
        arrows: false,
        autoPlay: true,
        autoPlaySpeed: 1000,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
      };

    return (<div className="w-full bg-dark overflow-hidden h-[424px] md:h-auto">
    <div className="
    w-full
    px-0
    py-6
    lg:px-0
    md:w-full
    lg:w-[1000px]
    lg:mx-auto
    xl:w-[1200px]
    text-white
    ">

      <div className="px-2 mx-auto">
        <img src={data} alt="" className="mx-auto" />
      </div>
    {/* <Slider {...settings}>
      <div className="px-2">
        <img src={data} alt="" />
      </div>
    </Slider> */}

    </div>
</div>)
}