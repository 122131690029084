

export default function Banner({
    firstText,
    secondText,
    image,
    src,
    bannerLink,
}){

    return (<div className="w-full my-2 md:my-8 ">
        {image?<div className="
        w-full
        px-2
        lg:px-0
        md:w-full
        lg:w-[1000px]
        lg:mx-auto
        xl:w-[1200px]
        flex
        items-center
        text-dark
        relative
            ">
                <div className="text-white absolute z-11 left-8">
                    {firstText?<div className="text-sm md:text-md">{firstText}</div>:null}
                    {secondText?<div className="text-md md:text-4xl font-bold">{secondText}</div>:null}
                </div>
                <img src={image} alt="" className="object-cover w-full h-full z-10" />
            </div>:
            <div className="
            w-full
            px-2
            lg:px-0
            md:w-full
            lg:w-[1000px]
            lg:mx-auto
            xl:w-[1200px]
            flex
            items-center
            text-dark
            relative
                ">
                <a href={bannerLink}>
                    <img src={src} alt="" className="object-cover w-full h-full z-10" />
                </a>
                    </div>}
    </div>)
}