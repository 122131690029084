// import React, { useEffect, useState } from 'react';
// import './tailwind.css';
// import HomePage from './components/pages/home';
// import CategoryPage from './components/pages/category';
// import ProductDetail from './components/pages/productDetail';
// import Contact from './components/pages/contact';
// import CustomPage from './components/pages/customStatic';
// import { CartProvider } from './context/cart';

// const App = () => {
//     const currentPage = window.wpReactTheme ? window.wpReactTheme.currentPage : 'customPage';

//     let pageComponent;
//     switch (currentPage) {
//         case 'home':
//             pageComponent = <HomePage />;
//             break;
//         case 'category':
//             pageComponent = <CategoryPage />;
//             break;
//         case 'productDetail':
//             pageComponent = <ProductDetail />;
//             break;
//         case 'contact':
//             pageComponent = <Contact />;
//             break;
//         case 'customPage':
//             pageComponent = <CustomPage />;
//             break;
//         default:
//             pageComponent = <div>404 error</div>;
//             break;
//     }

//     return (
//         <CartProvider>
//             {pageComponent}
//         </CartProvider>
//     );
// };

// export default App;



import React, { useEffect, useState } from 'react';
import './tailwind.css';
import HomePage from './components/pages/home';
import CategoryPage from './components/pages/category';
import ProductDetail from './components/pages/productDetail';
import Contact from './components/pages/contact';
import CustomPage from './components/pages/customStatic'
import PCart from './components/pages/cart';



import { CartProvider } from './context/cart';


const App = () => {
  // let page = 'home'
  const currentPage = window.wpReactTheme ? window.wpReactTheme.currentPage : 'customPage';

  console.log('----this is page '+currentPage+'-----')
  if(currentPage === 'home') {
    return <HomePage />
  } else if(currentPage === 'category') {
    return <CategoryPage />;
  } else if(currentPage === 'productDetail') {
    return <ProductDetail />;
  } else if(currentPage === 'contact') {
    return <Contact testText="pors" />;
  } else if(currentPage === 'customPage') {
    return <CustomPage />;
  } else if(currentPage === 'cart') {
    return <PCart />;
  }  else {
    return <div>404 error</div>;
  }
  // return <Contact testText="pors" />
};

export default App;
