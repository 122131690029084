

export default function PageHeader1({
    data,
    image,
}) {


    return(
    <div className="w-full relative h-[100px] md:h-[160px] overflow-hidden">
            <div className="
                h-[full]
                w-full
                md:w-full
                
                lg:w-[1000px]
                lg:mx-auto

                xl:w-[1200px]

                px-12 md:px-20 py-10
                absolute z-11 text-white
                flex flex-col items-start justify-end
                left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
                ">
                    <h1 className="text-3xl md:text-4xl font-bold leading-[40px] md:leading-[46px]">
                        {data?.name}
                    </h1>
            </div>
            <div className="w-full h-full bg-black opacity-70 z-10 absolute">&nbsp;</div>
                <img src={image?image:null}
                 alt="hero" 
                 className="w-full h-full object-cover absolute z-0" /> {/* Updated classes here */}
        </div>
    )
}